export const SidebarData = [
    {
        key:0,
        title: 'Home',
        path: 'home',
        pathHome: '/home',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },
    {
        key:1,
        title: 'Design',
        path: 'code-design',
        pathHome: '/home',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },
    {
        key:2,
        title: 'Stack',
        path: 'tech-stack',
        pathHome: '/home',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },
    {
        key:3,
        title: 'Projects',
        path: 'projects',
        pathHome: '/home',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },
    {
        key:4,
        title: 'Contact',
        path: 'contact',
        pathHome: '/home',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },
    {
        key:5,
        title: 'Profile',
        path: '/profile',
        pathHome: '/profile',
        // icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
    },

]
