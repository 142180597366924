import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './css/main.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Router
    basename={process.env.PUBLIC_URL}
    >
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


